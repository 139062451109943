import moment from "moment-timezone";
import ReactGA from "react-ga4";
import constants from "./constants";

if (process.env.NODE_ENV == "production") {
    ReactGA.initialize("G-Y9J7ZQ96DW")
}

export default {
    openInNewTab,
    formatPrice,
    addCommasToPrice,
    isOdd,
    handleExportCSV,
    sleep,
    countPossibleNumbers,
    lowestNumberWithLength,

    compressJson,
    compressJson_replaceProperties,
    sortData,

    getTimeLeft,
    calculateNewFutureDate,
    reverseCalculateFutureDate,
    isSecondDateMoreRecentOrSame,
    getMillisecSinceUNIXEpoch,

    toTitleCase,
    triggerGAEvent,
    camelCaseToTitleCase,
    toCamelCase,

    isTLDResellerSupported,

    updateLastUpdated_ObjectStore,
    isLastUpdatedOlderThanADay_ObjectStore,

    getUrlPath,
    getUrlQueries,
    navigateToPath,
    getDomain,
    getFullUrl,

    getPriceValueFromString,
    splitDomainTld,
};

function getPriceValueFromString(value) {
    let price = value?.replace(/[^\.0-9]/g,"");
    if (price) price = parseFloat(price);
    return isNaN(price)? "": (price < 0? 0: price);
}

function splitDomainTld(domain) {
    return {
        domain: domain.split(".")[0],
        tld: domain.split(".").slice(1).join("."),
    };
}

function isTLDResellerSupported(tld) {
    if (tld[0] != ".") tld = "." + tld;
    return constants.supportedTLDs[tld] ? true : false;
}

function toCamelCase(string) {
    return string.split(" ").map((s, i) => i > 0? s[0].toUpperCase() + s.substring(1):s).join("");
}

function camelCaseToTitleCase(camelCaseStr) {
    // Use a regular expression to add a space before each uppercase letter
    // Except for the first letter
    const titleCaseStr = camelCaseStr.replace(/([A-Z])/g, " $1");

    // Capitalize the first letter of each word and trim any leading spaces
    return titleCaseStr
        .split(" ")
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
        .trim();
}

function isLastUpdatedOlderThanADay_ObjectStore(objectStoreName) {
    const data = getLocalStorage("lastUpdated", { type: "object" });
    let lastUpdated_timestamp = data?.[objectStoreName];

    if (!lastUpdated_timestamp) {
        // No timestamp found
        return true;
    }

    const lastVisitTime = parseInt(lastUpdated_timestamp, 10); // Convert to number
    const oneDayInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const currentTime = Date.now();

    // Check if the last visit was more than a day ago
    return currentTime - lastVisitTime > oneDayInMillis;
}

function getLocalStorage(name, { type }) {
    let data = localStorage.getItem(name);
    switch (type) {
        case "object":
            data = data ? data : "{}";
            data = JSON.parse(data);
            break;
        case "array":
            data = data ? data : "[]";
            data = JSON.parse(data);
            break;
    }
    return data;
}

function updateLastUpdated_ObjectStore(objectStoreName) {
    let data = getLocalStorage("lastUpdated", { type: "object" });
    setLocalStorage("lastUpdated", {
        ...data,
        [objectStoreName]: Date.now(),
    });
}

function setLocalStorage(name, value) {
    localStorage.setItem(name, JSON.stringify(value));
}

function getDomain() {
    let fullUrl = getFullUrl();
    const match = /[^:\/]\//.exec(fullUrl);
    let pos = match.index + 1;
    return fullUrl.substring(0, pos);
}

function navigateToPath(path) {
    window.open(getDomain() + path, "_self");
}

function getFullUrl() {
    return window.location.href;
}

function getUrlQueries() {
    let fullUrl = getFullUrl();
    let startPos = fullUrl.indexOf("?");
    if (startPos) {
        let queries = fullUrl.substring(startPos + 1);
        let obj = {};
        queries.split("&").map(s => {
            let sections = s.split("=");
            obj[sections[0]] = sections[1];
        })

        return obj;
    }
    return ""; 
}

function getUrlPath() {
    let fullUrl = getFullUrl();

    // avoid matching with the first 2 slashes
    let startPos = fullUrl.indexOf("//");
    if (startPos > 0) startPos += 2;

    // get first slash position for path
    startPos = fullUrl.indexOf("/", startPos);

    return fullUrl.substring(startPos);
}

// format: { category, action, label }
function triggerGAEvent(params = {}) {
    if (process.env.NODE_ENV == "production") {
        ReactGA.event({ category: "app", ...params});
    } else {
        console.log("other than prod...", process.env.NODE_ENV);
    }
}

function toTitleCase(string) {
    return string
        .split(" ")
        .map((s) => s[0].toUpperCase() + s.substring(1))
        .join(" ");
}

function getMillisecSinceUNIXEpoch(stringDate) {
    return new Date(stringDate).getTime();
}

function isSecondDateMoreRecentOrSame({
    date1,
    date2,
    format = "MM/DD/YYYY HH:mm:ss",
    timezone = "America/Chicago",
}) {
    // Parse the input dates using moment with the specified timezone
    const firstDate = moment.tz(date1, format, timezone).valueOf(); // Get timestamp in milliseconds
    const secondDate = moment.tz(date2, format, timezone).valueOf(); // Get timestamp in milliseconds

    // Compare the two timestamps directly
    return secondDate >= firstDate;
}

function reverseCalculateFutureDate({
    formattedDate = "",
    timezone = "America/Chicago",
}) {
    let targetDate;

    // Check the type of formattedDate
    if (formattedDate instanceof Date) {
        targetDate = formattedDate;
    } else if (typeof formattedDate === "number") {
        targetDate = new Date(formattedDate);
    } else if (typeof formattedDate === "string" && formattedDate) {
        // Parse the input date string
        const [datePart, timePart] = formattedDate.split(" ");
        const [month, day, year] = datePart.split("/").map(Number);
        const [hours, minutes, seconds] = timePart.split(":").map(Number);

        // Create a Date object from the parsed date
        targetDate = new Date(year, month - 1, day, hours, minutes, seconds);
    } else {
        return {};
    }

    // Convert the target date to the specified timezone
    const targetDateInTimezone = new Date(
        targetDate.toLocaleString("en-US", { timeZone: timezone })
    );

    // Get the current date in the specified timezone
    const now = new Date();
    const currentDateInTimezone = new Date(
        now.toLocaleString("en-US", { timeZone: timezone })
    );

    // Calculate the difference
    const diffTime = targetDateInTimezone - currentDateInTimezone;

    // Determine the total difference in various time units
    const totalMinutes = Math.floor(diffTime / (1000 * 60));
    const totalHours = Math.floor(diffTime / (1000 * 60 * 60));
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const totalWeeks = Math.floor(totalDays / 7);
    const remainingDays = totalDays % 7;

    // Calculate years and months
    let years =
        targetDateInTimezone.getFullYear() -
        currentDateInTimezone.getFullYear();
    let months =
        targetDateInTimezone.getMonth() - currentDateInTimezone.getMonth();

    // Adjust years and months if needed
    if (months < 0) {
        years -= 1;
        months += 12;
    }

    // Calculate the remaining hours and minutes after removing years, months, weeks, and days
    const remainingHours =
        totalHours -
        (years * 365 * 24 +
            months * 30 * 24 +
            totalWeeks * 7 * 24 +
            remainingDays * 24);
    const remainingMinutes = totalMinutes - remainingHours * 60;

    // Return an object with the calculated differences
    return {
        years: years,
        months: months,
        weeks: totalWeeks,
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
        timezone: timezone,
    };
}

function calculateNewFutureDate({
    years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
    timezone = "America/Chicago",
    type = "numeric",
}) {
    // Convert all inputs to integers
    years = parseInt(years);
    months = parseInt(months);
    weeks = parseInt(weeks);
    days = parseInt(days);
    hours = parseInt(hours);
    minutes = parseInt(minutes);
    seconds = parseInt(seconds);

    // Create a new Date object for the current date and time in the specified timezone
    const now = new Date();
    const currentDateInTimezone = new Date(
        now.toLocaleString("en-US", { timeZone: timezone })
    );

    // Calculate the total number of milliseconds to add
    const totalMilliseconds =
        years * 365 * 24 * 60 * 60 * 1000 + // Years to milliseconds
        months * 30 * 24 * 60 * 60 * 1000 + // Months to milliseconds (approximation)
        weeks * 7 * 24 * 60 * 60 * 1000 + // Weeks to milliseconds
        days * 24 * 60 * 60 * 1000 + // Days to milliseconds
        hours * 60 * 60 * 1000 + // Hours to milliseconds
        minutes * 60 * 1000 + // Minutes to milliseconds
        seconds * 1000; // seconds to milliseconds

    // Add the total milliseconds to the current date
    if (type == "numeric") {
        return currentDateInTimezone.getTime() + totalMilliseconds;
    } else {
        const futureDate = new Date(
            currentDateInTimezone.getTime() + totalMilliseconds
        );

        // Format the date to MM/DD/YYYY HH:mm:ss
        const formattedDate = `${String(futureDate.getMonth() + 1).padStart(
            2,
            "0"
        )}/${String(futureDate.getDate()).padStart(
            2,
            "0"
        )}/${futureDate.getFullYear()} ${String(futureDate.getHours()).padStart(
            2,
            "0"
        )}:${String(futureDate.getMinutes()).padStart(2, "0")}:${String(
            futureDate.getSeconds()
        ).padStart(2, "0")}`;

        return formattedDate;
    }
}

function getTimeLeft({
    endTime,
    format = "MM/DD/YYYY HH:mm:ss",
    timezone = "America/Chicago",
}) {
    // Parse the end time string into a Moment object with the specified timezone
    let endDate;
    if (typeof endTime === "number") {
        endDate = moment.tz(endTime, timezone);
    } else {
        // Parse the end time string into a Moment object with the specified timezone
        endDate = moment.tz(endTime, format, timezone);
    }

    // Get the current date and time in the specified timezone
    const now = moment.tz(timezone);

    // Calculate the difference in various units
    const years = endDate.diff(now, "years");
    now.add(years, "years"); // Move 'now' forward by the number of years calculated
    const months = endDate.diff(now, "months");
    now.add(months, "months"); // Move 'now' forward by the number of months calculated
    const weeks = endDate.diff(now, "weeks");
    now.add(weeks, "weeks"); // Move 'now' forward by the number of weeks calculated
    const days = endDate.diff(now, "days");
    now.add(days, "days"); // Move 'now' forward by the number of days calculated
    const hours = endDate.diff(now, "hours");
    now.add(hours, "hours"); // Move 'now' forward by the number of hours calculated
    const minutes = endDate.diff(now, "minutes");
    now.add(minutes, "minutes"); // Move 'now' forward by the number of minutes calculated
    const seconds = endDate.diff(now, "seconds");

    // If the difference is negative, the end time has already passed
    if (
        years < 0 ||
        months < 0 ||
        weeks < 0 ||
        days < 0 ||
        hours < 0 ||
        minutes < 0 ||
        seconds < 0
    ) {
        return "Ended";
    }

    // Return a formatted string with the time left
    if (years > 0) {
        return `${years}y ${months ? `${months}mo` : ""}`;
    } else if (months > 0) {
        return `${months}mo ${weeks ? `${weeks}w` : ""}`;
    } else if (weeks > 0) {
        return `${weeks}w ${days ? `${days}d` : ""}`;
    } else if (days > 0) {
        return `${days}d ${hours ? `${hours}h` : ""}`;
    } else if (hours > 0) {
        return `${hours}h ${minutes ? `${minutes}m` : ""}`;
    } else if (minutes > 0) {
        return `${minutes}m ${seconds ? `${seconds}s` : ""}`;
    } else {
        return `${seconds}s`;
    }
}

function sortData({ data, isAsc, propertyName }) {
    data.sort((a, b) => {
        if (a[propertyName] > b[propertyName]) return isAsc ? 1 : -1;
        if (a[propertyName] < b[propertyName]) return isAsc ? -1 : 1;
        return 0;
    });
    return data;
}

function removeCharactersAtPositions(originalString, positions) {
    // Sort positions in descending order to avoid index shifting
    positions.sort((a, b) => b - a);

    // Create a new string by filtering out characters at specified positions
    let result = "";

    for (let i = 0; i < originalString.length; i++) {
        if (!positions.includes(i)) {
            result += originalString[i];
        }
    }

    return result;
}

function compressJson(data) {
    let newData = compressJson_replaceProperties(data);
    newData = compressJson_mapConsistentCharPositions(newData);
    newData = compressJson_domainNameFormula(newData);

    console.log(newData);

    return newData;
}

function compressJson_domainNameFormula(data) {
    // formula is sld + tld

    // make domain name empty
    return data.map((o) => {
        let obj = {};
        Object.keys(o).map((key, i) => {
            if (i == 1) obj[key] = "";
            else obj[key] = o[key];
        });

        return obj;
    });
}

function compressJson_mapConsistentCharPositions(data) {
    // initialize
    let positions = {};
    Object.keys(data[0]).map((key) => (positions[key] = []));

    // get all possible char for each position of the respective values
    data.map((o) => {
        Object.keys(o).map((key) => {
            let value = o[key];
            let charList = value.split("");
            charList.map((c, i) => {
                if (positions[key].length == i) {
                    positions[key].push({
                        [c]: i,
                    });
                } else if (positions[key].length > i) {
                    positions[key][i][c] = i;
                }
            });
        });
    });

    // keep positions with char value that is consistent
    let newObjData = {};
    for (let key in positions) {
        let data = positions[key].filter((o) => Object.keys(o).length == 1);
        newObjData[key] = data; // [{ '[char value]':[char position] }]
    }

    // remove the consistent chars from original data
    let newData = data.map((o) => {
        for (let key in o) {
            let value = o[key];
            let newValue = removeCharactersAtPositions(
                value,
                newObjData[key].map(
                    (o) => Object.keys(o).map((key) => o[key])[0]
                )
            );
            o[key] = newValue;
        }

        return o;
    });

    return newData;
}

function compressJson_replaceProperties(data) {
    let propertyNames = Object.keys(data[0]);
    const lowercaseAlphabetArray = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
    ];

    // replace the property names from data with the position of the matched name
    let newData = data.map((o) => {
        let newObj = {};
        propertyNames.map(
            (name, idx) => (newObj[lowercaseAlphabetArray[idx]] = o[name])
        );
        return newObj;
    });
    console.log("after edit", newData);
    return newData;
}

function lowestNumberWithLength(length) {
    if (length < 1) {
        return null; // Return null for non-positive lengths
    }

    if (length === 1) {
        return 0; // The lowest number with 1 digit is 0
    }

    // For lengths greater than 1, construct the lowest number
    return parseInt("1" + "0".repeat(length - 1), 10);
}

function countPossibleNumbers(length) {
    if (length < 1) {
        return 0; // No valid numbers for non-positive lengths
    }

    if (length === 1) {
        return 10; // Digits from 0 to 9
    }

    // For lengths greater than 1
    const firstDigitOptions = 9; // Digits from 1 to 9
    const otherDigitsOptions = Math.pow(10, length - 1); // Digits from 0 to 9 for remaining positions

    return firstDigitOptions * otherDigitsOptions;
}

function isOdd(number) {
    return number % 2 !== 0;
}

function openInNewTab(url) {
    window.open(url, "_blank"); // Open in a new tab
}

function formatPrice(amount) {
    // Convert the number to a string and remove the decimal part
    const dollars = Math.floor(amount).toFixed(2);

    // Reverse the string to make it easier to add commas
    const parts = (dollars + "").split(".");

    let newVal;
    if (parts.length == 1) {
        newVal = addCommasToPrice(parts[0]) + ".00";
    } else {
        newVal = addCommasToPrice(parts[0]) + "." + parts[1];
    }

    return newVal;
}

async function sleep(miliseconds, isDisplayed) {
    for (let i = 0; i < miliseconds / 1000; i++) {
        if (isDisplayed) console.log(i + 1);
        await new Promise((resolve) => setTimeout(resolve, 1000));
    }
}

function addCommasToPrice(val) {
    let sections = (val + "").split(".");
    let wholeNumber = sections[0];
    let cents = "";
    if (sections.length > 1)
        cents = sections[1].length == 2 ? sections[1] : `${sections[1]}0`;
    else cents = "00";
    return addCommasToWholeNumberPrice(wholeNumber) + "." + cents;
}

function addCommasToWholeNumberPrice(val) {
    const reversedDollars = (val + "").split("").reverse().join("");

    // Add commas every 3 digits
    let formattedDollars = "";
    for (let i = 0; i < reversedDollars.length; i++) {
        if (i > 0 && i % 3 === 0) {
            formattedDollars += ",";
        }
        formattedDollars += reversedDollars[i];

        // return formattedDollars;
    }

    // Reverse the string back to the original order
    formattedDollars = formattedDollars.split("").reverse().join("");
    return formattedDollars;
}

function handleExportCSV({ filteredRows, filename = "data" }) {
    const csvContent = filteredRows.join("\n");

    // Create a blob and download it
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename + ".csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
