const marketplaces = [
    {
        label: "Sav (Coming Soon)",
        value: "/domains/sav"
    },
    {
        label: "Dynadot (Coming Soon)",
        value: "/domains/dynadot"
    },
    // {
    //     label: "DropCatch",
    //     value: "/domains/dropcatch"
    // },
    // {
    //     label: "Catched",
    //     value: "/domains/catched"
    // },
    // {
    //     label: "Sedo",
    //     value: "/domains/sedo"
    // },
    // {
    //     label: "NameCheap",
    //     value: "/domains/namecheap"
    // },
    // {
    //     label: "GoDaddy",
    //     value: "/domains/godaddy"
    // },
    // {
    //     label: "Name.com",
    //     value: "/domains/name"
    // },
    // {
    //     label: "DomainMarket",
    //     value: "/domains/domainmarket"
    // },
    // {
    //     label: "Flippa",
    //     value: "/domains/flippa"
    // },
    // {
    //     label: "Epik",
    //     value: "/domains/epik"
    // },
    // {
    //     label: "NameJet",
    //     value: "/domains/namejet"
    // },
    // {
    //     label: "SnapNames",
    //     value: "/domains/snapnames"
    // },
    // {
    //     label: "NicSell",
    //     value: "/domains/nicsell"
    // },
    // {
    //     label: "NameSilo",
    //     value: "/domains/namesilo"
    // },
    // {
    //     label: "DomainLore",
    //     value: "/domains/domainlore"
    // },
    // {
    //     label: "Ebay",
    //     value: "/domains/ebay"
    // },
    // {
    //     label: "Park.io",
    //     value: "/domains/parkio"
    // },
];

const supportedTLDs = {
    ".com": 1,
    ".biz": 1,
    ".club": 1,
    ".homes": 1,
    ".tech": 1,
    ".pro": 1,
    ".rocks": 1,
    ".boats": 1,
    ".bond": 1,
    ".online": 1,
    ".store": 1,
    ".info": 1,
    ".global": 1,
    ".me": 1,
    ".today": 1,
    ".co.uk": 1,
    ".org.uk": 1,
    ".sbs": 1,
    ".icu": 1,
    ".design": 1,
    ".vu": 1,
    ".uk": 1,
    ".me.uk": 1,
    ".run": 1,
    ".fund": 1,
    ".art": 1,
    ".fan": 1,
    ".xn--qxa6a": 1,
    ".net": 1,
    ".rent": 1,
    ".link": 1,
    ".watches": 1,
    ".site": 1,
    ".space": 1,
    ".kaufen": 1,
    ".click": 1,
    ".beauty": 1,
    ".digital": 1,
    ".cc": 1,
    ".life": 1,
    ".top": 1,
    ".games": 1,
    ".ninja": 1,
    ".xn--nqv7f": 1,
    ".uno": 1,
    ".org": 1,
    ".rentals": 1,
    ".boutique": 1,
    ".events": 1,
    ".systems": 1,
    ".organic": 1,
    ".camp": 1,
    ".tennis": 1,
    ".tools": 1,
    ".reisen": 1,
    ".guide": 1,
    ".asia": 1,
    ".market": 1,
    ".glass": 1,
    ".attorney": 1,
    ".xn--5tzm5g": 1,
    ".de": 1,
    ".cruises": 1,
    ".international": 1,
    ".cards": 1,
    ".gratis": 1,
    ".com.co": 1,
    ".services": 1,
    ".blog": 1,
    ".house": 1,
    ".lighting": 1,
    ".solutions": 1,
    ".movie": 1,
    ".llc": 1,
    ".zone": 1,
    ".photography": 1,
    ".cfd": 1,
    ".sc": 1,
    ".work": 1,
    ".eu": 1,
    ".support": 1,
    ".com.sc": 1,
    ".graphics": 1,
    ".net.bz": 1,
    ".kids": 1,
    ".ai": 1,
    ".school": 1,
    ".fans": 1,
    ".country": 1,
    ".directory": 1,
    ".democrat": 1,
    ".academy": 1,
    ".florist": 1,
    ".rehab": 1,
    ".haus": 1,
    ".world": 1,
    ".consulting": 1,
    ".software": 1,
    ".construction": 1,
    ".blue": 1,
    ".fit": 1,
    ".hospital": 1,
    ".gold": 1,
    ".chat": 1,
    ".kim": 1,
    ".plus": 1,
    ".productions": 1,
    ".dental": 1,
    ".network": 1,
    ".finance": 1,
    ".tips": 1,
    ".institute": 1,
    ".cheap": 1,
    ".mba": 1,
    ".horse": 1,
    ".football": 1,
    ".pw": 1,
    ".vision": 1,
    ".food": 1,
    ".luxe": 1,
    ".melbourne": 1,
    ".rest": 1,
    ".irish": 1,
    ".care": 1,
    ".bz": 1,
    ".vana": 1,
    ".flowers": 1,
    ".skin": 1,
    ".inc": 1,
    ".org.sc": 1,
    ".delivery": 1,
    ".lol": 1,
    ".co.bz": 1,
    ".engineer": 1,
    ".property": 1,
    ".supplies": 1,
    ".coupons": 1,
    ".xn--czrs0t": 1,
    ".futbol": 1,
    ".pet": 1,
    ".reviews": 1,
    ".training": 1,
    ".actor": 1,
    ".style": 1,
    ".lease": 1,
    ".news": 1,
    ".tours": 1,
    ".foundation": 1,
    ".bio": 1,
    ".agency": 1,
    ".show": 1,
    ".dealer": 1,
    ".company": 1,
    ".ventures": 1,
    ".ceo": 1,
    ".build": 1,
    ".community": 1,
    ".sale": 1,
    ".limited": 1,
    ".computer": 1,
    ".domains": 1,
    ".estate": 1,
    ".health": 1,
    ".auction": 1,
    ".schule": 1,
    ".pizza": 1,
    ".diamonds": 1,
    ".cool": 1,
    ".gmbh": 1,
    ".shopping": 1,
    ".green": 1,
    ".nyc": 1,
    ".ltd": 1,
    ".shoes": 1,
    ".catering": 1,
    ".vote": 1,
    ".watch": 1,
    ".xn--unup4y": 1,
    ".social": 1,
    ".city": 1,
    ".navy": 1,
    ".fyi": 1,
    ".report": 1,
    ".broker": 1,
    ".quest": 1,
    ".diy": 1,
    ".restaurant": 1,
    ".town": 1,
    ".wedding": 1,
    ".jewelry": 1,
    ".monster": 1,
    ".gd": 1,
    ".place": 1,
    ".autos": 1,
    ".xyz": 1,
    ".ooo": 1,
    ".net.vc": 1,
    ".org.vc": 1,
    ".limo": 1,
    ".net.sc": 1,
    ".parts": 1,
    ".video": 1,
    ".farm": 1,
    ".bargains": 1,
    ".dentist": 1,
    ".voto": 1,
    ".condos": 1,
    ".qpon": 1,
    ".taxi": 1,
    ".vet": 1,
    ".media": 1,
    ".help": 1,
    ".kitchen": 1,
    ".cab": 1,
    ".builders": 1,
    ".codes": 1,
    ".works": 1,
    ".archi": 1,
    ".surgery": 1,
    ".black": 1,
    ".pub": 1,
    ".supply": 1,
    ".memorial": 1,
    ".car": 1,
    ".coffee": 1,
    ".xn--c1avg": 1,
    ".fm": 1,
    ".plumbing": 1,
    ".gallery": 1,
    ".recipes": 1,
    ".marketing": 1,
    ".direct": 1,
    ".fitness": 1,
    ".lat": 1,
    ".doctor": 1,
    ".team": 1,
    ".tires": 1,
    ".guru": 1,
    ".photo": 1,
    ".onl": 1,
    ".christmas": 1,
    ".financial": 1,
    ".furniture": 1,
    ".dog": 1,
    ".ws": 1,
    ".wiki": 1,
    ".immobilien": 1,
    ".vacations": 1,
    ".vip": 1,
    ".deals": 1,
    ".holdings": 1,
    ".voyage": 1,
    ".center": 1,
    ".viajes": 1,
    ".shiksha": 1,
    ".family": 1,
    ".university": 1,
    ".hosting": 1,
    ".soccer": 1,
    ".surf": 1,
    ".yoga": 1,
    ".mobi": 1,
    ".vin": 1,
    ".toys": 1,
    ".singles": 1,
    ".business": 1,
    ".discount": 1,
    ".xn--i1b6b1a6a2e": 1,
    ".bike": 1,
    ".enterprises": 1,
    ".email": 1,
    ".coach": 1,
    ".associates": 1,
    ".solar": 1,
    ".maison": 1,
    ".management": 1,
    ".tienda": 1,
    ".salon": 1,
    ".exchange": 1,
    ".engineering": 1,
    ".lifestyle": 1,
    ".vc": 1,
    ".education": 1,
    ".villas": 1,
    ".net.co": 1,
    ".living": 1,
    ".credit": 1,
    ".equipment": 1,
    ".boston": 1,
    ".fishing": 1,
    ".golf": 1,
    ".cars": 1,
    ".giving": 1,
    ".miami": 1,
    ".sh": 1,
    ".college": 1,
    ".game": 1,
    ".sarl": 1,
    ".promo": 1,
    ".gifts": 1,
    ".holiday": 1,
    ".cleaning": 1,
    ".rich": 1,
    ".fail": 1,
    ".motorcycles": 1,
    ".xn--vhquv": 1,
    ".xn--fjq720a": 1,
    ".forsale": 1,
    ".flights": 1,
    ".ruhr": 1,
    ".expert": 1,
    ".insure": 1,
    ".audio": 1,
    ".ski": 1,
    ".money": 1,
    ".contractors": 1,
    ".mom": 1,
    ".blackfriday": 1,
    ".theater": 1,
    ".contact": 1,
    ".trading": 1,
    ".gripe": 1,
    ".rodeo": 1,
    ".healthcare": 1,
    ".airforce": 1,
    ".cam": 1,
    ".yachts": 1,
    ".sydney": 1,
    ".reise": 1,
    ".hair": 1,
    ".co": 1,
    ".org.bz": 1,
    ".website": 1,
    ".diet": 1,
    ".auto": 1,
    ".fun": 1,
    ".charity": 1,
    ".fo": 1,
    ".partners": 1,
    ".xn--6frz82g": 1,
    ".luxury": 1,
    ".degree": 1,
    ".army": 1,
    ".land": 1,
    ".mortgage": 1,
    ".fashion": 1,
    ".pictures": 1,
    ".lgbt": 1,
    ".one": 1,
    ".vg": 1,
    ".markets": 1,
    ".london": 1,
    ".com.vc": 1,
    ".legal": 1,
    ".io": 1,
    ".press": 1,
    ".garden": 1,
    ".properties": 1,
    ".com.bz": 1,
    ".case": 1,
    ".nom.co": 1,
    ".jetzt": 1,
    ".host": 1,
    ".bar": 1,
    ".clinic": 1,
    ".energy": 1,
    ".claims": 1,
    ".photos": 1,
    ".pink": 1,
    ".moda": 1,
    ".industries": 1,
    ".capital": 1,
    ".clothing": 1,
    ".ink": 1,
    ".makeup": 1,
    ".group": 1,
    ".investments": 1,
    ".careers": 1,
    ".best": 1,
    ".gives": 1,
    ".cooking": 1,
    ".wang": 1,
    ".republican": 1,
    ".fish": 1,
    ".express": 1,
    ".lawyer": 1,
    ".casa": 1,
    ".hockey": 1,
    ".cyou": 1,
    ".spa": 1,
    ".studio": 1,
    ".ac": 1,
    ".live": 1,
    ".xn--e1a4c": 1,
    ".red": 1,
    ".accountants": 1,
    ".technology": 1,
    ".us": 1,
    ".travel": 1,
    ".tax": 1,
    ".repair": 1,
    ".xn--ngbc5azd": 1,
    ".camera": 1,
    ".cafe": 1,
    ".exposed": 1,
    ".tickets": 1,
    ".pics": 1,
    ".rip": 1,
    ".baby": 1,
    ".cash": 1,
    ".apartments": 1,
};

export default { marketplaces, supportedTLDs };
