import axios from "axios";
import { useEffect } from "react";

export default function useFetchData({ isNotUsingAPI, setData, filters = [], sort = {} }) {
    // Fetch data from the API
    const fetchData = async () => {
        console.log(filters, sort)
        try {
            const response = await axios.get(
                `https://api.example.com/data?filter=${filters}&sort=${sort}`
            );
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        if (!isNotUsingAPI) fetchData(); // Fetch initial data
    }, [filters, sort]);
}
