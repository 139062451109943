import React, { useEffect } from "react";

import styles from "./IdealTable.module.scss";

import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import customColumns from "../customColumns";
import useTableFilters from "../../tableFilters/useTableFilters";
import { Hourglass } from "react-loader-spinner";
import sortingFns from "../sortingFns";
import useFetchData from "../useFetchData";

//These are the important styles to make sticky column pinning work!
//Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
//View the index.css file for more needed styles such as border-collapse: separate
const getCommonPinningStyles = (column) => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
        isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn =
        isPinned === "right" && column.getIsFirstColumn("right");

    return {
        boxShadow: isLastLeftPinnedColumn
            ? "-4px 0 4px -4px gray inset"
            : isFirstRightPinnedColumn
            ? "4px 0 4px -4px gray inset"
            : undefined,
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
        right:
            isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
        opacity: isPinned ? 0.95 : 1,
        position: isPinned ? "sticky" : "relative",
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
    };
};

export default function IdealTable({
    columns: columns_props,
    initData,
    isCheckboxNeeded,
    isUsingAPI,
    isExpandable,
    isDomainActionHidden,
    isLoading,
    customStyle = {}
}) {
    const [data, setData] = React.useState(() => initData);
    const [expanded, setExpanded] = React.useState({});
    const [sort, setSort] = React.useState({ id: columns_props[0].accessorKey, type: "asc"});
    const [columns] = React.useState(() => {
        // update header format
        columns_props = columns_props.map((o) => ({
            ...o,
            header: () => <span>{o.header}</span>,
        }));

        if (isCheckboxNeeded) {
            columns_props = customColumns.getCheckboxColumns(columns_props);
        }

        if (isExpandable) {
            columns_props = customColumns.getExpandablesColumns(columns_props);
        }

        if (!isDomainActionHidden) {
            columns_props = customColumns.getDomainActionColumns(columns_props);
        }
        return columns_props;
    });

    const {
        filterFns,
        columnFilters,
        setColumnFilters,
        exportButton,
        components: filterComponents,
    } = useTableFilters({
        columns,
        data
    });

    useFetchData({
        setData,
        filters: columnFilters,
        sort,
        isNotUsingAPI: !isUsingAPI
    });

    const table = useReactTable({
        data,
        columns,
        state: {
            columnPinning: {
                right: [...(!isDomainActionHidden ? ["domainAction"] : [])],
                left: [
                    ...(isCheckboxNeeded ? ["checkbox"] : []),
                    ...(isExpandable ? ["expand"] : []),
                ],
            },
            ...(isExpandable ? { expanded } : {}),
            ...(!isUsingAPI? { columnFilters } :{}),
        },
        sortingFns,

        getFilteredRowModel: getFilteredRowModel(), //client side filtering
        getCoreRowModel: getCoreRowModel(),
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.subRows,
        ...(isExpandable ? { getExpandedRowModel: getExpandedRowModel() } : {}),
        ...(!isUsingAPI ? { onColumnFiltersChange: setColumnFilters, filterFns } : {}),
        getSortedRowModel: getSortedRowModel(),
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true,
        // columnResizeMode: "onChange",
    });

    const { rows } = table.getRowModel();

    //The virtualizers need to know the scrollable container element
    const tableContainerRef = React.useRef(null);

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 50, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            typeof window !== "undefined" &&
            navigator.userAgent.indexOf("Firefox") === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5,
    });

    const virtualRows = rowVirtualizer.getVirtualItems();

    function addSubheader(data) {
        return data?.map((o) => {
            if (o.subrowHeader && o?.subRows) {
                let newObj = {
                    ...o,
                    subRows: [
                        {
                            subrowHeader: o.subrowHeader,
                        },
                    ].concat(o.subRows),
                };
                delete newObj.subrowHeader;

                return newObj;
            } else return o;
        });
    }

    useEffect(() => {
        setData(addSubheader(initData));
    }, [initData]);

    let tableBodyRow = (row) => {
        return row.getVisibleCells().map((cell, idx) => {
            return (
                <td
                    className={`${styles.cell} ${
                        row.depth > 0 ? styles["subrow-cell"] : ""
                    }`}
                    style={{
                        flex: "flex",
                        ...getCommonPinningStyles(cell?.column),
                    }}
                    key={cell.id}
                >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            );
        });
    };

    return (
        <div className={`${styles.container}`}>
            <div className={styles["bg"]}>
                <div className={styles["table-actions-container"]}
                    style={customStyle}
                >
                    <div className={styles["table-actions"]}>
                        {filterComponents}

                        {exportButton(
                            table.getFilteredRowModel().rows,
                            columns_props.map((o) => ({
                                id: o.accessorKey,
                                header: o.header,
                            }))
                        )}
                    </div>
                </div>

                <div
                    className={styles["table-container"]}
                    ref={tableContainerRef}

                    style={customStyle}
                >
                    <Hourglass
                        visible={isLoading}
                        wrapperStyle={{
                            padding: "50px",
                        }}
                        width="100"
                        height={"100"}
                        colors={["#ffffff", "#ffffff"]}
                        ariaLabel="hourglass-loading"
                    />

                    <table
                        className={styles.table}
                        style={{
                            width: table.getTotalSize(),
                            visibility: isLoading ? "hidden" : "visible",
                        }}
                    >
                        <thead className={styles.thead}>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    className={styles["header-row"]}
                                    key={headerGroup.id}
                                >
                                    {headerGroup.headers.map((header) => {
                                        const { column } = header;

                                        return (
                                            <th
                                                className={styles.th}
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                //IMPORTANT: This is where the magic happens!
                                                style={{
                                                    ...getCommonPinningStyles(
                                                        column
                                                    ),
                                                }}
                                            >
                                                <div
                                                    {...{
                                                        className:
                                                            header.column.getCanSort()
                                                                ? "cursor-pointer select-none"
                                                                : "",
                                                        onClick: (e) => {
                                                            let type = header.column.getIsSorted();
                                                            setSort({ id: column.id, type })
                                                            header.column.getToggleSortingHandler()(e)
                                                        }
                                                    }}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                              header.column
                                                                  .columnDef
                                                                  .header,
                                                              header.getContext()
                                                          )}
                                                    {{
                                                        asc: " ⯅",
                                                        desc: " ⯆",
                                                    }[
                                                        !header.column.columnDef
                                                            .hideSortIcon
                                                            ? header.column.getIsSorted()
                                                            : null
                                                    ] ?? null}
                                                </div>

                                                {/* <div
                                                {...{
                                                    onDoubleClick: () =>
                                                        header.column.resetSize(),
                                                    onMouseDown:
                                                        header.getResizeHandler(),
                                                    onTouchStart:
                                                        header.getResizeHandler(),
                                                    className: `resizer ${
                                                        header.column.getIsResizing()
                                                            ? "isResizing"
                                                            : ""
                                                    }`,
                                                }}
                                            >ll</div> */}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody
                            className={styles.tbody}
                            style={{
                                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                            }}
                        >
                            {virtualRows.map((virtualRow) => {
                                const row = rows[virtualRow.index];
                                let subheader = row?.original?.subrowHeader;

                                return (
                                    <tr
                                        key={row.id}
                                        className={styles.rows}
                                        //needed for dynamic row height measurement
                                        data-index={virtualRow.index}
                                        //measure dynamic row height
                                        ref={(node) =>
                                            rowVirtualizer.measureElement(node)
                                        }
                                        style={{
                                            display: "flex",
                                            position: "absolute",
                                            transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                                            width: "100%",
                                        }}
                                    >
                                        {subheader ? (
                                            <td
                                                className={
                                                    styles[
                                                        "subrow-headers-wrapper"
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles["subrow-headers"]
                                                    }
                                                >
                                                    {subheader}
                                                </div>
                                            </td>
                                        ) : (
                                            tableBodyRow(row)
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
