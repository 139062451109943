import functions from "../helpers/functions";

export default function Redirect() {
    let queries = functions.getUrlQueries();
    if (typeof queries == "object") {
        let { url, aff, status } = queries;
        url = decodeURIComponent(url);
        status = decodeURIComponent(status);
        status = functions.toCamelCase(status);
        aff = functions.toCamelCase(aff);

        functions.triggerGAEvent({
            action: aff + "_" + status,
        })

        window.open(url, "_self");
    }
    return ""
}