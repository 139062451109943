import styles from "./Home.module.scss";
import extensionScreenshot from "../../images/extension-screenshot.png";
import functions from "../../helpers/functions";
import chromeIcon from "../../images/chrome-icon.svg";

import bulkIcon from "../../images/bulk-icon.png";
import compareIcon from "../../images/compare-icon.png";
import userFriendlyIcon from "../../images/user-friendly-icon.png";
import availabilityIcon from "../../images/availability-icon.png";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

export default function Home() {
    let highlights = [
        {
            header: "Bulk Appraisal",
            description:
                "Quickly appraise multiple domains at once, saving you valuable time and effort in your domain valuation process.",
            image: "https://i.ibb.co/4mhYcDK/bulk-icon.png",
        },
        {
            header: "Comparable Sales",
            description:
                "Access a rich database of comparable sales to make informed decisions based on real market trends.",
            image: "https://i.ibb.co/mFK6g0v/compare-icon.png",
        },
        {
            header: "User-Friendly Interface",
            description:
                "Enjoy a sleek and intuitive design that makes navigating the appraisal process seamless, even for beginners.",
            image: "https://i.ibb.co/GnDgkWR/user-friendly-icon.png",
        },
        {
            header: "Availability Check",
            description:
                "Instantly verify the availability of domains, allowing you to seize opportunities and make informed purchasing decisions on the spot.",
            image: "https://i.ibb.co/QvCGwy8/availability-icon.png",
        },
    ];

    return (
        <div className={styles.container}>
            {/* Hero Section */}
            <div className={styles["hero-section"]}>
                {/* Title */}
                <h1>
                    <b>Simplify</b> Your Search With
                    <br />
                    <b>Quick</b> & <b>Easy</b> Bulk Appraisals
                </h1>

                {/* Brief Explanation */}
                <div className={styles["description-container"]}>
                    <p>
                        Effortlessly evaluate multiple domains in one go,
                        streamlining your appraisal process and saving you
                        precious time. Our user-friendly interface allows you to
                        quickly navigate through bulk appraisals, making
                        informed decisions simpler than ever!
                    </p>
                </div>

                {/* Action */}
                <Link
                    className={styles["action-button"]}
                    to={process.env.REACT_APP_STORE_URL}
                    onClick={() => {
                        functions.triggerGAEvent({
                            action: "homeHero_install",
                        })
                    }}
                    children={
                        <>
                            <img src={chromeIcon} />
                            Install Now - It's Free
                        </>
                    }
                />

                {/* Sreenshot of Working Tool */}
                <img className={styles.screenshot} 
                src={
                    "https://lh3.googleusercontent.com/Yo1PsT09k-lYbvG4_O-g9uNR5cAI1glVcpFigGJyMTwQVzHfQJNz_UqTiNpFF_UJk5snDECszPV6S-46q-6_lU9q_g=s1280-w1280-h800"
                    // "https://i.ibb.co/PxRNwjx/extension-screenshot.png"
                    } />
            </div>

            {/* A list of image and corresponding highlights. Alternate position of both. */}
            <div className={styles["highlights-wrapper"]}>
                <h2>Features</h2>

                {highlights.map((o, idx) => {
                    let isOdd = functions.isOdd(idx);
                    let image = o.image;
                    let header = o.header;
                    let description = o.description;

                    let display = "";
                    if (isOdd) {
                        display = (
                            <>
                                <img src={image} />
                                <div
                                    className={styles["highlight-description"]}
                                >
                                    <h2>{header}</h2>
                                    <p>{description}</p>
                                </div>
                            </>
                        );
                    } else {
                        display = (
                            <>
                                <div
                                    className={styles["highlight-description"]}
                                >
                                    <h2>{header}</h2>
                                    <p>{description}</p>
                                </div>
                                <img src={image} />
                            </>
                        );
                    }

                    return <div key={idx} className={styles.highlight}>{display}</div>;
                })}
            </div>

            {/* Call-to-Action section */}
            <div className={styles["call-to-action-section"]}>
                <h2>
                    Save <b>Time</b> and <b>Effort</b> <br />
                    to Search Premium Domains
                </h2>

                {/* Action */}
                <Link
                    className={styles["action-button"]}
                    to={process.env.REACT_APP_STORE_URL}
                    onClick={() => {
                        functions.triggerGAEvent({
                            action: "homeBottomAction_install",
                        })
                    }}
                    children={
                        <>
                            <img src={chromeIcon} />
                            Install Now
                        </>
                    }
                />
            </div>
        </div>
    );
}
